import styled from 'styled-components'
import media from '../../styles/media'
import COLORS from '../ThemeColors'
import locationIcon from '../../assets/images/location-icon.svg'
import locationIconSelected from '../../assets/images/location-icon-selected.svg'
import locationIconBlur from '../../assets/images/location-icon-blur.svg'

export const SortBoxes = styled.div`
  display: ${props => (props.isActive ? "block" : "none")};
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  font-size: 12px;

  @media (max-width: 375px) {
    .order-buttons {
      width: 100%;
      display: block;
      padding: 0 20px;
    }
  }
 
  ${media.md`
  display: flex;
  justify-content: flex-end;
  `}

  ${media.lg`
  font-size: 13px;
  padding-right: 30px;
  `}
`

export const OrderButton = styled.button`
  transition: all 0.5s ease-in-out 0s;
  border: solid 1px;
  border-radius: 20px;
  margin-right: 10px;
  font-size: inherit;
  font-weight: 600;
  line-height: 12px;
  cursor: pointer;
  padding: 10px 20px;
  min-height: 38px;
  margin: 10px 15px;

  @media (max-width: 370px) {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &.btn-order {
    width: 80px;
    ${media.md`
      width: auto;
    `}
  }

  &.order-button-right {
    background: url(${locationIcon}) no-repeat;
    background-size: 17px;
    padding-left: 25px;
    padding-right: 5px;
    background-position: 7px;
    ${media.sm`
    background-position: 10px;
    padding-left: 35px;
    padding-right: 10px;
    `}
  }

  &.order-button-combined {
    padding-left: 5px;
    padding-right: 5px;
    ${media.sm`
    padding-left: 10px;
    padding-right: 10px;
    `}

    &.left {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 1px solid white;
      display: inline-block;
      margin-right: 0;
    }

    &.right {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 1px solid white;
      display: inline-block;
      margin-left: 0;
    }
  }

  &.order-button-off {
    background-color: transparent;
    border-color: ${COLORS.white};
    color: ${COLORS.white};
  }

  &.order-button-on {
    background-color: ${COLORS.white};
    color: ${COLORS.blueSelect};
    border-color: solid 1px ${COLORS.white};

    &.left {
      border-right-color: ${COLORS.white};
    }

    &.right {
      border-left-color: ${COLORS.white};
    }

    &.order-button-right {
      background-image: url(${locationIconSelected});
    }
  }

  &:hover {
    background-color: ${COLORS.white};
    color: ${COLORS.blueSelect};
    border-color: ${COLORS.white};

    &.order-button-combined {
      &.left {
        border-right-color: ${COLORS.white};
      }

      &.right {
        border-left-color: ${COLORS.white};
      }
    }

    &.order-button-right {
      background-image: url(${locationIconBlur});
    }

    &.order-button-on {
      background-color: ${COLORS.white};
      color: ${COLORS.blueSelect};
      &.order-button-right {
        background-image: url(${locationIconSelected});
      }
    }
  }

  ${media.md`
    font-size: 12px;
    margin: 10px;
  `}

`

export const SortLabel = styled.div`
  display: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 10px;

  ${media.sm`
  flex: 0 0 auto;
  max-width: auto;
  margin-right: 20px;
  margin-bottom: 0;
  `}
  ${media.md`
  display: block;
  font-size: 0.9rem;
  `}
`
