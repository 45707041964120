import React from 'react'
import { withRouter } from 'react-router-dom'
import Helmet from 'react-helmet'
import 'babel-polyfill'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../assets/css/global.css'
import Header from '../components/Header'
import Footer from '../components/Footer'
import favicon from '../assets/images/favicon.png'

const Layout = ({ children }) => (
  <div id="page-wrap">
    <Helmet
      title="Beneficios Locales | Diners Club"
      meta={[
        { name: 'description', content: 'Beneficios Locales Diners Club' },
        { name: 'keywords', content: 'beneficios, locales' },
        {
          name: 'viewport',
          content:
              'width=device-width, initial-scale=1.0, shrink-to-fit=no, maximum-scale=1, user-scalable=0',
        },
      ]}
      link={[
        {
          rel: 'shortcut icon',
          type: 'image/x-icon',
          href: `${favicon}`,
        },
      ]}
    />
    <>
      <Header />
      <div id="outer-container">{children}</div>
      <Footer />
    </>
  </div>
)

export default withRouter(Layout)
