import styled from 'styled-components'
import { Link } from 'react-router-dom'
import COLORS from '../ThemeColors'
import media from '../../styles/media'
import logos from '../../assets/images/icons-rrss-white.svg'
import logoMundos from '../../assets/images/mundos.png'
import logoMundosMobile from '../../assets/images/mundos-mobile.png'

export const FooterWrapper = styled.footer`
  background-color: ${COLORS.newDarkGray};
  padding: 10px 0;
  text-align: center;
  ${media.sm`
  padding: 10px 0;
  `}
`

export const Copyright = styled.div`
  color: ${COLORS.white};
  font-size: 12px;
  text-align: center;
  ${media.md`
  font-size: 14px;
  `};
  
  .copyright-text {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    padding-bottom: 24px;
    ${media.sm`
    width: 50%;
    padding-bottom: 0;
    text-align: left;
    `}
  }

  .terms-conditions {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    text-align: center;
    font-size: 12px;
    font-weight: 'bold';
    ${media.md`
      font-size: 18px;
    `}
  }
`
export const HelpDesk = styled.div`
  color: ${COLORS.white};
  font-size: 24px;
  text-align: center;
  background-image: url(${logoMundosMobile});
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  padding: 50px 20px;
  height: 750px;
  width: 100%;
  ${media.md`
    background-image: url(${logoMundos});
    height: 650px;
    font-size: 34px;
    padding: 90px 0;
  `}
  
  label{
    display: block;
    font-size: 18px;
    margin: 10px 0;
    padding: 0px 50px;
  }
`
export const Attendance = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;

  a:first-child {
    margin-bottom: 30px;

    ${media.md`
      margin-right: 30px;
    `}    
  }
`

export const SocialNetworks = styled.div`
  display: block;
  text-align: center;
  height: 220px;
  padding: 30px;
  background: ${COLORS.blackBackground};
  color: ${COLORS.white};
  font-size: 18px;
  ${media.md`
    padding: 20px 100px;
    height: 100px;
  `}

  .menu-social-network, .menu-social-network li{
    display: inline-block;
    margin: -5px 10px;

    label{
      margin: 10px 25%;
    }

    a:hover{
      opacity: 1;
    }

    ${media.sm`
      label{
        margin: 10px 30%;
      }
    `}

    ${media.md`
      margin: -5px 8px;

      label{
        margin: -5px 8px -5px 50px;
      }
    `}
  }

  .menu-social-network {
    margin-left: 5%;
    padding: 20px 0;

    ${media.sm`
      margin: 0 20%;
    `}

    ${media.md`
      margin: 0 0 0 11%;
    `}

    ${media.lg`
      margin: 0 0 0 15%;
    `}

    ${media.xl`
      margin: 0 0 0 22%;
    `}
  }

  .icon-face{
    background-position: 0 -31px;
  }
  .icon-instagram{
    background-position: 0 -62px;
  }
  .icon-linkedin{
    background-position: 0 -94px;
  }
  .icon-twitter{
    background-position: 0 0;
  }
  .icon-other{
    background-position: 0 -125px;
  }
`
export const ExternalLinkBlank = styled.a`
  color: ${COLORS.white};
  font-size: 16px;
  font-weight: bold;
  margin-left: 5px;
  &:hover {
    text-decoration: underline;
    color: ${COLORS.white};
  }

  ${media.md`
    margin-left: 10%;
  `}

  ${media.lg`
    margin-left: 15%;
  `}

  ${media.xl`
    margin-left: 20%;
  `}
`

export const SocialLink = styled.a`
    color: ${COLORS.gray};
    background-image: url(${logos});
    background-repeat: no-repeat;
    display: block;
    min-width: 25px;
    height: 25px;
    text-indent: -999999rem;
    opacity: 0.7;
    width: 24px;
    
  &:hover {
    text-decoration: none;
    color: ${COLORS.white};
  } 
`

export const ExternalLink = styled.a`
  transition: all 0.5s ease-in-out 0s;
  color: ${COLORS.white};
  background-color: ${COLORS.blueSelect};
  border: solid 2px ${COLORS.blueSelect};
  outline: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  padding: 12px 35px;
  text-align: center;
  height: 50px;
  width: 250px;
  margin-top: 30px;
  border-radius: 40px;

  ${media.sm`
  padding: 8px 20px;
  height: 41px;
  `}

  &:hover {
    color: ${COLORS.white};
    text-decoration: none;
    cursor: pointer;
  }
`
export const CustomLink = styled(Link)`
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
  ${media.sm`
  padding: 5px 0;
  `}
  ${media.md`
  padding: 9px 0;
  `}
`

export const ContentFooter = styled.div`
    display: flex;
    flex-direction: column;
    ${media.md`
      margin-left: 30%;
    `}
`

export const TitleFooter = styled.span`
  margin-top: 130%;
  font-size: 24px;
  ${media.sm`
    margin-top: 50px;
    font-size: 42px;
  `}
  ${media.lg`
    margin-top: 0;
    font-size: 42px;
  `}
`

export const TitleDownload = styled.span`
  margin-top: 20px;
  font-size: 32px;
`