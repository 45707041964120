const { default: styled } = require('styled-components')
const { default: media } = require('../../styles/media')
const { default: COLORS } = require('../ThemeColors')

export const BannerWrapper = styled.div`
  padding: 20px 0 0;
  position: relative;
  color: ${COLORS.white};
  ${media.sm`
  padding: 0;
  `}

  .container {
    padding: 0;
  }
`

export const Banner = styled.img`
  object-fit: cover;
  width: 100%;
  height: auto;
  margin: 0;
  ${media.sm`
    border-radius: 10px;
    margin: 30px 0;
  `}
`

export const ContainerWrapper = styled.div`
  height: 100%;
  position: relative;
  padding: 0;

  ${media.sm`
    position: absolute;
    padding: 35px 0;
    width: 100%;
    top: 0;
  `}
`
export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  & div:nth-child(2) {
    margin-top: auto;
    margin-bottom: auto;
  }
`

export const Name = styled.p`
  font-weight: 300;
  font-size: 28px;
  line-height: 1.71;
  text-align: center;
  color: ${COLORS.darkBlue};
  ${media.sm`
  font-size: 52px;
  line-height: 56px;
  font-weight: 300;
  color: ${COLORS.white};
  text-align: left;
  margin-bottom: 10px;
`}
`

export const ShortDescription = styled.p`
  font-size: 20px;
  line-height: 28px;
`
