import styled from 'styled-components'
import media from '../../../styles/media'
import COLORS from '../../ThemeColors'

export const NavbarWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: start;
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 72px;
  right: ${props => (props.open ? "0" : "-100%")};
  width: 100%;
  height: 90vh;
  transition: right 0.3s linear;
  font-size: 14px;
  z-index: 2;

  ${media.sm`
    /* display: none; */
    flex-direction: row;
    /* position: initial; */
    height: auto;
    justify-content: center;
    /* background: white; */
    width: 100vw;
    height: 900vh;
  `} 


  a.item-menu {
    display: flex;
    padding: 15px 20px;
    color: ${COLORS.lightBlue};
    text-decoration: none;
  }

`;

export const ExternalLink = styled.a`
  transition: all 0.5s ease-in-out 0s;
  justify-content: center;
  color: ${COLORS.lightBlue};
  background-color: ${COLORS.white};
  outline: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding: 12px 35px;
  text-align: center;
  width: 250px;
  height: 52px;
  margin-top: 30px;
  border-radius: 40px;
  padding: 8px 20px;

  &:hover {
    color: ${COLORS.white};
    text-decoration: none;
    cursor: pointer;
  }
`
